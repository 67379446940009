<template>
    <a-modal v-model="visible" title="清洁屋详情"  width="700px" :footer="null">
        <a-descriptions :title="houseResult.houseName">
            <a-descriptions-item label="清洁屋编号">
                {{houseResult.houseCode}}
            </a-descriptions-item>
            <a-descriptions-item label="小区名称">
                {{houseResult.centerName}}
            </a-descriptions-item>
            <a-descriptions-item label="所属区域">
                {{houseResult.areaName}}
            </a-descriptions-item>
            <a-descriptions-item label="所属街道">
                {{houseResult.streetName}}
            </a-descriptions-item>
        </a-descriptions>
    </a-modal>
</template>

<script>
import { queryStreetPointHouseApi } from '@/api/center'
export default {
    data() {
        return{
            visible: false,
            record: {},
            houseResult: {}
        }
    },
    methods: {
        showModal(data) {
            this.visible = true
            this.record = data
            this.queryStreetPointHouse()
        },
        queryStreetPointHouse() {
            queryStreetPointHouseApi( this.record.pointCode).then(res => {
                this.houseResult = res.result
            })
        }
    }
}
</script>